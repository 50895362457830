import { getPriceFormatted } from "../../../utils/currency";
import {
  getVehicle,
  getVehicleSpecifications,
  getDealersList,
  getSimilar
} from "@/api/product";

const state = {
  vehicle: {},
  financeId: 0,
  minDeposit: 0,
  dealers: [],
  dealersArray: [],
  defaultDeposit: 0,
  financialContribution: {},
  similarVehicles: []
};

const REVERSER_TAX_VALUE = 1.23;

const calculatePriceWithDiscounts = (
  price,
  financialContribution,
  rootGetters
) => {
  const { hasHODCampaign } = financialContribution;
  const isOpenDrive = rootGetters["getIsOpenDrive"];
  const includeRetake = rootGetters["getIncludeRetake"];
  const withFinancing = rootGetters["getWithFinancing"];

  if (hasHODCampaign && isOpenDrive && withFinancing) {
    // Open to drive
    price = state.vehicle?.prices?.find(
      v => v.priceType == "OpenDriveFinancialDiscountPrice"
    )?.actualValue;

    if (includeRetake) {
      // Open to drive with tradein
      price = state.vehicle?.prices?.find(
        v => v.priceType == "OpenDriveFinancialDiscountPriceRetake"
      )?.actualValue;
    }
  } else if (withFinancing && !isOpenDrive) {
    // Credit to drive
    price = state.vehicle?.prices?.find(
      v => v.priceType == "FinancialDiscountPrice"
    )?.actualValue;

    if (includeRetake) {
      // Credit to drive
      price = state.vehicle?.prices?.find(
        v => v.priceType == "FinancialDiscountPriceRetake"
      )?.actualValue;
    }
  }
  return price;
};

const getters = {
  getFuel: state => state.vehicle?.fuel,
  getVersion: state => state.vehicle?.commercialDescription,
  getConsumption: state =>
    state.vehicle?.carDetailSpecification?.specifications?.filter(
      d => d.title === "consumption"
    ).length &&
    state.vehicle?.carDetailSpecification?.specifications?.filter(
      d => d.title === "consumption"
    )[0].description
      ? `${
          state.vehicle?.carDetailSpecification?.specifications?.filter(
            d => d.title === "consumption"
          )[0].description
        }`
      : "",
  getTransmission: state => state.vehicle?.transmission,
  getReservationType: state => state.vehicle?.reserveType,
  getDealers: state => state.dealers,
  getDealersArray: state => state.dealersArray,
  getFormattedTotalPrice: (state, rootGetters) => {
    const pricePVP = state?.financialContribution
      ? calculatePriceWithDiscounts(
          state.vehicle?.prices?.find(v => v.priceType == "TotalPrice")
            ?.actualValue,
          state?.financialContribution,
          rootGetters
        )
      : state.vehicle?.prices?.find(v => v.priceType == "TotalPrice")
          ?.actualValue;
    return getPriceFormatted(pricePVP);
  },
  getCondition: state => state.vehicle?.carDetailCard?.condition,
  getConditionId: state => state.vehicle?.carDetailCard?.conditionId,

  getCreditTypeDescription: state => creditType =>
    state.vehicle?.creditTypes[creditType - 1].creditName,
  getCreditTypeDetails: state => creditType => {
    return state.vehicle?.creditTypes.find(credit => credit.id === creditType);
  },
  getPower: state =>
    state.vehicle?.carDetailSpecification?.specifications?.find(
      vd => vd.title == "power"
    ).description,
  getVehicleTotalPrice: state => state.vehicle?.totalPrice,
  getTooltip: state => state.vehicle?.carDetailCard?.legalInfo,
  getVehicleTan: state => state.vehicle?.carDetailFinance?.tan,
  getVehicleMtic: state => state.vehicle?.carDetailFinance?.mtic,
  getVehicleFee: state => state.vehicle?.carDetailFinance?.fee,
  getVehicleFinanceId: state => state.financeId,
  getMinDeposit: state => state.minDeposit,
  getVin: state => state.vehicle?.vin,
  getKilometers: state => state.vehicle?.carDetailCard?.kilometers,
  getMonth: state => state.vehicle?.carDetailCard?.month,
  getYear: state => state.vehicle?.carDetailCard?.year,
  getSelectedVehicle: state => state.vehicle,
  getFavorite: state => state.vehicle?.favorite,
  getCarSimilarVehicles: state => state.similarVehicles?.slice(0, 3),
  getVehicleDealerMunicipality: state => state.vehicle?.dealerMunicipality,
  getCarDetailEquipment: state => state.vehicle?.carDetailEquipment,
  getCarDetailEquipmentByCategory: state =>
    state.vehicle?.carDetailEquipmentByCategory?.equipmentsByCategory,
  getFinanceId: state => state.vehicle?.financeId,
  getCarDetailFaqs: state => {
    return state.vehicle?.carDetailFaqs?.faqs;
  },
  getCarSpecifications: state => {
    return state.vehicle?.carDetailSpecifications;
  },
  getCarSpecificationsMapped: state => {
    return state.vehicle?.carDetailSpecification?.specifications?.map(vd => ({
      title: vd.title,
      specification: vd.description
    }));
  },
  getCarFinance(state) {
    return state.vehicle?.carDetailFinance;
  },
  getModel: state => state.vehicle?.model,
  getRawTaeg: state => Number(state.vehicle?.taeg).toFixed(2),

  getTaeg: state =>
    getPriceFormatted(state.vehicle?.taeg, {
      currency: "EUR",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1
    }).replace("€", "%"),

  getDescription: state => state.vehicle?.commercialDescription,
  getVehiclePrice: state => state.vehicle?.pricePvp,
  getFormattedPrice: (state, rootGetters) => {
    const price = state.vehicle?.prices?.find(
      v => v.priceType == "FinancialDiscountPrice"
    )?.actualValue;
    var pricePVP = "---";
    if (price) {
      pricePVP = state?.financialContribution
        ? calculatePriceWithDiscounts(
            price,
            state?.financialContribution,
            rootGetters
          )
        : price;
    } else {
      pricePVP = state?.financialContribution
        ? calculatePriceWithDiscounts(
            state.vehicle?.prices?.find(v => v.priceType == "TotalPrice")
              ?.actualValue,
            state?.financialContribution,
            rootGetters
          )
        : state.vehicle?.prices?.find(v => v.priceType == "TotalPrice")
            ?.actualValue;
    }
    return price === ""
      ? "---"
      : getPriceFormatted(pricePVP, {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
  },
  getFormattedPreviousPrice: state => {
    return !state.vehicle?.previousPrice
      ? "---"
      : getPriceFormatted(state.vehicle?.previousPrice, {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
  },
  getPrice: state => state.vehicle?.pricePvp,
  getPreviousPrice: state => state.vehicle?.previousPrice,

  getPriceBasedOnType: (state, rootGetters) => financingInformation => {
    let price;
    let current;
    if (financingInformation.hasFinancing) {
      current = state.vehicle?.carDetailCard?.prices?.filter(
        p => p.priceType === "FinancialDiscountPrice"
      );
    } else {
      current = state.vehicle?.carDetailCard?.prices?.filter(
        p => p.priceType === "TotalPrice"
      );
    }

    if (current && current.length) {
      if (financingInformation.isPrevious) {
        price = current[0].previousValue
          ? current[0].previousValue
          : current[0].actualValue;
      } else if (state?.financialContribution) {
        price = calculatePriceWithDiscounts(
          current[0].actualValue,
          state?.financialContribution,
          rootGetters
        );
      } else {
        price = current[0].actualValue;
      }
    } else {
      price = 0;
    }
    return price;
  },
  getFormattedPriceBasedOnType: (
    state,
    rootGetters
  ) => financingInformation => {
    let price;
    let current;

    if (financingInformation.hasFinancing) {
      current = state.vehicle?.carDetailCard?.prices?.filter(
        p => p.priceType === "FinancialDiscountPrice"
      );
    } else {
      current = state.vehicle?.carDetailCard?.prices?.filter(
        p => p.priceType === "TotalPrice"
      );
    }

    if (current && current.length) {
      price = financingInformation.isPrevious
        ? current[0].previousValue
        : state?.financialContribution
        ? calculatePriceWithDiscounts(
            current[0].actualValue,
            state?.financialContribution,
            rootGetters
          )
        : current[0].actualValue;
    } else {
      price = 0;
    }

    return price.length && price > 0
      ? "---"
      : getPriceFormatted(price, {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
  },

  getRawMonthlyPrice: state => state.vehicle?.monthlyPrice,

  getFormattedMonthlyPrice: state => {
    return state.vehicle?.monthlyPrice === ""
      ? "--"
      : getPriceFormatted(Number(state.vehicle?.monthlyPrice), {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
  },

  getFormattedMonthlyPriceSimple: state =>
    getPriceFormatted(Number(state.vehicle?.monthlyPrice), {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }),

  getImg: state =>
    state.vehicle?.imageUrl ||
    require("../../../assets/images/placeholder.jpg"),

  getFormattedTaeg: state => {
    return state.vehicle?.taeg === ""
      ? "--"
      : getPriceFormatted(state.vehicle?.taeg, {
          currency: "EUR",
          minimumFractionDigits: 1,
          maximumFractionDigits: 1
        }).replace("€", "%");
  },

  getNbrPaymentsValue: state => state.vehicle?.carDetailFinance?.months,

  getFormattedInitialDeposit: state =>
    getPriceFormatted(
      Number(state.vehicle?.carDetailFinance?.depositValue).toFixed(2),
      {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }
    ),

  getInitialDeposit: state => state.vehicle?.carDetailFinance?.depositValue,

  getRawCapitalToFinance: state =>
    state.vehicle?.carDetailFinance?.capitalFinance,

  getFormattedCapitalToFinance: state =>
    getPriceFormatted(
      Number(state.vehicle?.carDetailFinance?.capitalFinance).toFixed(2),
      {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }
    ),

  getVehicleFaqs: state => state.vehicle?.carDetailFaqs?.faqs,

  getVehicleInitialDepositFormatted: state =>
    getPriceFormatted(state.vehicle?.carDetailFinance?.depositValue, {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }),

  getVehicleInitialDeposit: state =>
    state.vehicle?.carDetailFinance?.depositValue,

  getVehicleInitialExpenses: () =>
    state.vehicle?.carDetailFinance?.initialExpenses,

  getVehicleNumberMonths: state => {
    return state.vehicle?.carDetailFinance?.months;
  },

  getVehicleResidualValue: state => {
    const res = Number(state.vehicle?.carDetailFinance?.residualValue)?.toFixed(
      2
    );
    return res === "NaN" || res == "0.00" ? null : res;
  },

  getFormattedResidualValue: state =>
    getPriceFormatted(
      Number(state.vehicle?.carDetailFinance?.residualValue).toFixed(2),
      {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }
    ),

  getVehicleResidualValueOD: (state, rootGetters) => (creditType, months) => {
    var res = state.vehicle?.creditTypes[creditType]?.residualValues.filter(
      resVal => resVal.months === months
    )[0];
    if (res) {
      res =
        res.valueType === "P"
          ? res.value *
            rootGetters.getPriceBasedOnType({
              hasFinancing: false,
              isPrevious: false
            })
          : res.value;
    } else {
      res = "NaN";
    }
    return res === "NaN" || res == "0.00" ? null : res;
  },

  getVehicleRate: state => creditType => {
    return state.vehicle?.creditTypes[creditType]?.rate;
  },
  getVehicleMinRate: state => creditType => {
    return state.vehicle?.creditTypes[creditType]?.minRate;
  },

  // FIXME: Why is this here??!!
  // eslint-disable-next-line no-unused-vars
  getSessionRetake: state => JSON.parse(sessionStorage.getItem("retake")),

  getDefaultDeposit: state => state.defaultDeposit,
  getFinancialContribution: state => state.financialContribution,
  getCreditDiscounts: state =>
    state.financialContribution.discountDealer +
    state.financialContribution.discountTrader +
    state.financialContribution.discountFinancial,
  getHODDiscounts: state =>
    state.financialContribution.discountDealerHOD +
    state.financialContribution.discountTraderHOD +
    state.financialContribution.discountFinancialHOD,
  getCreditDiscountsNoFinance: state =>
    state.financialContribution.discountDealer +
    state.financialContribution.discountTrader,
  getHODDiscountsNoFinance: state =>
    state.financialContribution.discountDealerHOD +
    state.financialContribution.discountTraderHOD,
  getBasePVP: state => {
    const totalPrice = state.vehicle?.carDetailCard?.prices?.find(
      p => p.priceType === "TotalPrice"
    )?.actualValue;
    return (
      totalPrice / REVERSER_TAX_VALUE +
      state.financialContribution.discountDealer +
      state.financialContribution.discountTrader
    );
  },
  getExternalImages: state =>
    state.vehicle?.carDetailImage?.staticImages?.externalImages.map(
      img => img.url
    ),
  // TODO: remove mock
  getMainImage: state =>
    state.vehicle?.carDetailCard?.imageUrl ||
    "https://assets.maxterauto.com/vo/imagenes/h_img_100_45457_381955_1634831164.jpg",
  getAOS: state => state.vehicle?.externalPlayer?.autoOnShow
};

const mutations = {
  setDealers(state, dealers) {
    state.dealers = dealers.data;
  },
  setVehicle(state, vehicle) {
    state.vehicle = vehicle;
  },
  setCarFaqs(state, faqs) {
    state.vehicle.carDetailFaqs = { faqs: faqs };
  },
  setSimilarVehicles(state, vehicles) {
    state.similarVehicles = vehicles;
  },
  updateVehicleFinance(
    state,
    {
      pvp,
      taeg,
      fee,
      nrPayments,
      capitalToFinance,
      initialValue,
      residualValue,
      legalInfo,
      mtic,
      rate: tan
    }
  ) {
    state.vehicle = {
      ...state.vehicle,
      pricePvp: pvp,
      taeg,
      monthlyPrice: fee,
      carDetailCard: {
        ...state.vehicle.carDetailCard,
        legalInfo
      },
      carDetailFinance: {
        ...state.vehicle.carDetailFinance,
        months: nrPayments,
        capitalFinance: capitalToFinance,
        depositValue: initialValue,
        residualValue,
        mtic,
        tan
      }
    };
  },

  setVehicleNumberMonths(state, val) {
    state.vehicle.carDetailFinance.months = val;
  },
  setVehicleInitialDeposit(state, val) {
    state.vehicle.carDetailFinance.depositValue = val;
  },
  setVehicleFinanceId(state, id) {
    state.financeId = id;
  },
  setMinDeposit(state, val) {
    state.minDeposit = val;
  },
  setEmptyVehicleValues(state, val) {
    state.vehicle.pricePvp = val;
    state.vehicle.monthlyPrice = val;
    state.vehicle.taeg = val;
  },
  setDefaultDeposit(state, val) {
    state.defaultDeposit = val;
  },
  setFinancialContribution(state, val) {
    state.financialContribution = val;
  },
  setDealersArray(state, val) {
    state.dealersArray = val;
  }
};

const actions = {
  async storeVehicle({ commit }, { vin, fid }) {
    try {
      commit("setEmptyVehicleValues", "");
      commit("spinnerModule/setLoading", true, { root: true }); // -> 'someMutation'
      const res = await getVehicle(vin, fid);
      const resS = await getVehicleSpecifications(vin);
      const { carDetailCard, carCardSimilar } = res.data.data;
      commit("setVehicle", {
        carCardSimilar,
        carDetailSpecifications: resS.data.data,
        ...carDetailCard,
        ...res.data.data
      });
      commit("setMinDeposit", res?.data?.data?.carDetailFinance?.depositValue);
      commit(
        "setDefaultDeposit",
        res?.data?.data?.carDetailFinance?.depositValue
      );
      commit(
        "setFinancialContribution",
        res?.data?.data?.carDetailFinance?.financialContribution
      );
    } catch (err) {
      console.error("Could not fetch vehicle details from backend");
      commit("setVehicle", { vin, fid });
    } finally {
      commit("spinnerModule/setLoading", false, { root: true }); // -> 'someMutation'
    }
  },
  async fetchSimilarVehicles({ commit }, { vin }) {
    try {
      const res = await getSimilar(vin);
      commit("setSimilarVehicles", res.data.data);
    } catch (err) {
      console.error("Could not fetch similar vehicles from backend");
      commit("setSimilarVehicles", []);
    }
  },
  async storeDealers({ commit }) {
    try {
      commit("spinnerModule/setLoading", true, { root: true });
      const response = await getDealersList();
      commit("setDealers", response);
      const dealers = [];
      response.data.data.map(item => {
        item.dealers.map(dealer => {
          dealers.push(dealer);
        });
      });
      commit("setDealersArray", dealers);
    } catch (err) {
      console.log(err);
      console.error("Could not fetch dealers");
    } finally {
      commit("spinnerModule/setLoading", false, { root: true });
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
